import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/global/contact-area/contact-two'

const seo = {
  title: "Services",
  description: "Invent, build, integrate, scale and upgrade your applications with us!",
  image: "/dark-logo.png",
  pathname: "/services",
};

const ITServicePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />   
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Services"
    />
    <main className="site-wrapper-reveal">
        <ServicesArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default ITServicePage
 