import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NonStretchedImage = props => {
    let normalizedProps = props;
    const image = getImage(props.image);
    let alignment;
    if(props.align === 'right'){
        alignment = '0 0 0 auto'
    } else if(props.align === 'left'){
        alignment = '0 auto 0 0'
    }else{
        alignment = '0 auto'
    }
    
    normalizedProps = {...normalizedProps.fluid, aspectRatio: 1}
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                position: props.isAbsolute ? 'absolute' : 'relative',
                width: props.isAbsolute ? props.fluid.presentationWidth : '',
                maxWidth: props.fluid.presentationWidth,
                margin: alignment, 
            },
        }
    }   

    return <GatsbyImage image={image} {...normalizedProps} />;
}

export default NonStretchedImage;